html{
  font-size: 1em;
  background-color: #2f3344;
  color:white;
  font-family: "Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

@mixin text-button($size){
  font-size:#{$size}rem;
  font-weight:700;
  color:black;
  display: inline-block;
  border: 1px solid #e67bb1;
  border-radius:5px;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #2e3135;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

h1{
  margin-top:5%;
}

.App {
  text-align: center;
  > div{
    width:90%;
    margin:5% 5%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-button{
  @include text-button(1);
}

#letters{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  

  button{
    @include text-button(1.3);
    margin: 3px;
  }
    
}

button {
  @include text-button(1.3);
}

#CurrentLetters{
  font-size:1.8rem;
}

.cheat {
  text-align:center;
  @include text-button(1);
  span{
    display:block;
    font-size:2rem;
  }

  &.on{
    span{
      display:block;
      margin-top:10px;
      font-size:1.5rem;
    }
  }

}


@media only screen and (max-width: 600px) {
  #letters{
    button{
      font-size:1.1rem;
      cursor:pointer;
      padding: .5rem 1rem;
      margin: 3px;
    }
  }

  h1{
    margin-top:10%;
  }
}

@media screen and (orientation:landscape)
  and (max-device-width: 800px) {

    html{
      font-size:.95em;
    }

    #letters{
      button{
        cursor:pointer;
        padding: .4rem .6rem;
      }
    }

    .App {
      > div{
        width:90%;
        margin:2% 5%;
      }

      h4, p{
        margin:2% 0;
      }
    }

}
